const ABBR_MAPPING = {
  'I ': 'Ziffer römisch 1',
  'I.': 'Ziffer römisch 1',
  'II ': 'Ziffer römisch 2',
  'II.': 'Ziffer römisch 2',
  'III ': 'Ziffer römisch 3',
  'III.': 'Ziffer römisch 3',
  'IV ': 'Ziffer römisch 4',
  'IV.': 'Ziffer römisch 4',
  'V ': 'Ziffer römisch 5',
  'V.': 'Ziffer römisch 5',
  'VI ': 'Ziffer römisch 6',
  'VI.': 'Ziffer römisch 6',
  'VII ': 'Ziffer römisch 7',
  'VII.': 'Ziffer römisch 7',
  'VIII ': 'Ziffer römisch 8',
  'VIII.': 'Ziffer römisch 8',
  'IX ': 'Ziffer römisch 9',
  'IX.': 'Ziffer römisch 9',
  'X ': 'Ziffer römisch 10',
  'X.': 'Ziffer römisch 10',
  'XI ': 'Ziffer römisch 11',
  'XI.': 'Ziffer römisch 11',
  'XII ': 'Ziffer römisch 12',
  'XII.': 'Ziffer römisch 12',

  'Abs.': 'Absatz',
  Ads: 'Anzeigen',
  ARD: 'Arbeitsgemeinschaft der öffentlich-rechtlichen Rundfunkanstalten der Bundesrepublik Deutschland',
  'Art.': 'Artikel',
  BAGFW: 'Bundesarbeitsgemeinschaft der Freien Wohlfahrtspflege',
  BSL: 'Bundesweite Sofortlotterien',
  BMW: 'Bayerische Motoren Werke',
  CDN: 'Content Delivery Network',
  'DM ': 'Deutsche Mark',
  'DM.': 'Deutsche Mark',
  'DS-GVO': 'Datenschutz-Grundverordnung',
  DSGVO: 'Datenschutz-Grundverordnung',
  DSD: 'Deutsche Stiftung Denkmalschutz',
  DLTB: 'Deutscher Lotto- und Totoblock',
  DISQ: 'Deutsches Institut für Servicequalität',
  DOSB: 'Deutscher Olympischer Sportbund',
  'Mo.': 'Montag',
  'Di.': 'Dienstag',
  'Mi.': 'Mittwoch',
  'Do.': 'Donnerstag',
  'Fr.': 'Freitag',
  'Sa.': 'Samstag',
  'So.': 'Sonntag',
  DtGV: 'Deutsche Gesellschaft für Verbraucherstudien',
  'EU-': 'Europäische Union',
  'EU ': 'Europäische Union',
  'EU.': 'Europäische Union',
  'EU,': 'Europäische Union',
  'GlüStV 2021': 'Glücksspielstaatsvertrag 2021',
  GTE: 'Gran Turismo Electric (Hybridantrieb)',

  // Reihenfolge beachten!
  'Co. OHG': 'Compagnie offene Handelsgesellschaft',
  'Co. KG': 'Compagnie Kommanditgesellschaft',
  GmbH: 'Gesellschaft mit beschränkter Haftung',
  OHG: 'offene Handelsgesellschaft',
  KG: 'Kommanditgesellschaft',
  mbH: 'mit beschränkter Haftung',

  HRB: 'Handelsregister Abteilung B',
  'per https': 'über Hypertext Transfer Protocol Secure',
  'Inc.': 'Incorporated',
  iOS: 'iPhone Operating System',
  'i. S. d.': 'im Sinne der',
  'J.': 'Jahren',
  'Kl.': 'Klasse',
  'lit.': 'littera',
  LLG: 'Landeslotteriegesellschaft',
  'Max.': 'Maximum',
  'Min.': 'Minimum',
  'Mio.': 'Millionen',
  'Monatl.': 'Monatlich',
  MStV: 'Medienstaatsvertrag',
  ntv: 'ntv Nachrichtenfernsehen GmbH',
  NRW: 'Nordrhein-Westfalen',
  OASIS: 'Online-Abfrage-Spieler-Informations-System',
  ODR: 'Online dispute resolution / Online-Streitbeilegung',

  'd.o.o.': 'Gesellschaft mit begrenzter Haftung',
  's. o.': 'siehe oben',
  ' o.g.': 'oben genannten',
  ' o.': 'oder',

  TÜV: 'Technischer Überwachungs-Verein',
  TüV: 'Technischer Überwachungs-Verein',
  URL: 'Uniform Resource Locator - Internetadresse',
  USA: 'United States of America',
  'USt.': 'Umsatzsteuer',
  'usw.': 'und so weiter',
  'vgl.': 'vergleiche',
  SEA: 'Search Engine Advertising / Suchmaschinenwerbung',

  'u. a.': 'unter anderem',
  'u.': 'und',

  PKW: 'Personenkraftwagen',
  'Ref.': 'Referat',
  SES: 'Service Experience Score',
  VDP: 'Vulnerability Disclosure Policy',
  VOD: 'Video on Demand',
  VW: 'Volkswagen',
  XXL: 'extra extra large (äußerst groß)',
  ZDF: 'Zweites Deutsches Fernsehen',
  'z.B.': 'zum Beispiel',
  'z. B.': 'zum Beispiel',
  'zzgl.': 'zuzüglich',
};

const ABBR_REGEX = new RegExp(
  Object.keys(ABBR_MAPPING).join('|').replaceAll('.', '\\.'),
  'g'
);

export function containsAbbreviationTargets(text: string) {
  return typeof text === 'string'
    ? Object.keys(ABBR_MAPPING).some((abbr) => text.includes(abbr))
    : false;
}
export default function replaceAbbreviations(text: string) {
  return typeof text === 'string'
    ? {
        __html: text.replace(
          ABBR_REGEX,
          (match) => `<abbr title="${ABBR_MAPPING[match]}">${match}</abbr>`
        ),
      }
    : { __html: text };
}
